import React, { useState } from 'react';
//CSS
import '../assets/css/map.css';

function GPSOptions({ onOptionChange, onLoadGpxFile }) {
    const [selectedOption, setSelectedOption] = useState('');
    // const [selectedFile, setSelectedFile] = useState('');

    const predefinedFiles = ['29km Marche', '34km Ronde Des Douaniers', '56km Raid Du Golfe', '70km Raid Du Golfe', '100km Raid Du Golfe', '175km Grand Raid Du Golfe'];

    const handleOptionChange = (e) => {
        const option = e.target.value;
        setSelectedOption(option);
        onOptionChange(option); // Appel de la fonction de rappel avec la nouvelle option sélectionnée
    };

    const handleFileChange = (e) => {
        const file = e.target.value;
        // setSelectedFile(file);
        // Charger le fichier GPX sélectionné dans la carte
        onLoadGpxFile(file);
    };

    return (
        <div className="gps-options">
            {/* <select value={selectedOption} onChange={handleOptionChange} className="custom-select">
                <option value="">Sélectionner un parcours</option>
                <option value="import">Importer un fichier GPX</option>
                <option value="predefined">Utiliser un fichier GPX prédéfini</option>
            </select> */}
            {/* {selectedOption === 'predefined' && ( */}

                <select onChange={handleFileChange} className="custom-select">
                    <option value="" disabled selected>Quelle course ?</option>
                    {predefinedFiles.map((file, index) => (
                        <option key={index} value={file}>
                            {file}
                        </option>
                    ))}
                </select>

            {/* )} */}
        </div>
    );

}

export default GPSOptions;
