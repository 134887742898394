import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-gpx';
import ravitoIcon from '../assets/images/ravito.png';

const GPXUploader = forwardRef(({ map }, ref) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [gpxLayers, setGpxLayers] = useState([]);
    const [legendItems, setLegendItems] = useState([]);
    const colors = ['#0000FF', '#FF0000', '#00FF00', '#800080'];

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleUpload = (overlay) => {
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const gpxData = e.target.result;
                const color = colors[gpxLayers.length % colors.length];
                const gpxLayer = new L.GPX(gpxData, {
                    async: true,
                    polyline_options: {
                        color: color
                    },
                    marker_options: {
                        startIconUrl: null,
                        endIconUrl: null,
                        shadowUrl: null,
                        wptIconUrls: {
                            '': ravitoIcon
                        },
                        iconSize: [32, 32],
                        iconAnchor: [16, 32]
                    }
                });

                gpxLayer._gpxFileName = selectedFile.name;

                gpxLayer.addTo(map);
                gpxLayer.on('loaded', () => {
                    console.log('GPX layer loaded');
                    addArrowsAtKilometers(gpxLayer);
                    gpxLayer.on('mouseover', () => {
                        gpxLayer.bringToFront();
                    });
                    updateLegend(gpxLayer);
                });

                if (overlay === 'replace') {
                    gpxLayers.forEach((layer) => {
                        map.removeLayer(layer);
                    });
                    setGpxLayers([gpxLayer]);
                    setLegendItems([]);
                } else if (overlay === 'overlay') {
                    setGpxLayers([...gpxLayers, gpxLayer]);
                }
            };
            reader.readAsText(selectedFile);
        }
    };

    const addArrowsAtKilometers = (gpxLayer) => {
        // Ajouter des flèches à chaque kilomètre
    };

    useEffect(() => {
        updateLegend();
    }, [gpxLayers]);

    const updateLegend = (gpxLayer) => {
        if (gpxLayer) {
            console.log(map.hasLayer(gpxLayer));
            const newLegendItem = (
                <div key={gpxLayer._leaflet_id}>
                    <input 
                        type="checkbox" 
                        id={gpxLayer._leaflet_id} 
                        checked={map.hasLayer(gpxLayer)} 
                        onChange={() => toggleLayerVisibility(gpxLayer)} 
                    />
                    <label htmlFor={gpxLayer._leaflet_id}>{gpxLayer._gpxFileName}</label>
                </div>
            );
            setLegendItems((prevLegendItems) => [...prevLegendItems, newLegendItem]);
        }
    };

    const toggleLayerVisibility = (gpxLayer) => {
        if (map.hasLayer(gpxLayer)) {
            map.removeLayer(gpxLayer);
        } else {
            map.addLayer(gpxLayer);
        }
    };

    useImperativeHandle(ref, () => ({
        updateLegend
    }));

    return (
        <div>
            <h2>Importateur GPX</h2>
            <input type="file" onChange={handleFileChange} />
            <button onClick={() => handleUpload('replace')}>Remplacer</button>
            <button onClick={() => handleUpload('overlay')}>Superposer</button>
            {gpxLayers.length > 0 && (
                <div id="legend">
                    <div key="ravito">
                        <img src={ravitoIcon} alt="Ravito Icon" />
                        <label>Ravitaillement</label>
                    </div>
                    {legendItems}
                </div>
            )}
        </div>
    );
});

export default GPXUploader;
