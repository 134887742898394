import React, { useState, useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-gpx';
import 'leaflet-routing-machine';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import GPSOptions from './GPSOptions';
import GPXUploader from './GPXUploader';
import GeoLocationPopup from './GeoLocationPopup';

// Importer les fichiers GPX prédéfinis
import gpxFile29km from '../assets/gpx_files/29km_marche.gpx';
import gpxFile34km from '../assets/gpx_files/34km_rdd.gpx';
import gpxFile56km from '../assets/gpx_files/56km_rdg.gpx';
import gpxFile70km from '../assets/gpx_files/70km_rdg.gpx';
import gpxFile100km from '../assets/gpx_files/100km_rdg.gpx';
import gpxFile175km from '../assets/gpx_files/175km_rdg.gpx';

// Importer les images des icônes
import ravitoIcon from '../assets/images/ravito.png';
import startIconImage from '../assets/images/start-icon.png';  // Icône pour le départ
import endIconImage from '../assets/images/end-icon.png';  // Icône pour l'arrivée
import userIconImage from '../assets/images/user-icon.png';  // Icône pour la position de l'utilisateur

//CSS
import '../assets/css/map.css';

function Map() {
    const [userLocation, setUserLocation] = useState(null);
    const [map, setMap] = useState(null);
    const [selectedOption, setSelectedOption] = useState('');
    const [legendItems, setLegendItems] = useState([]);
    const [destination, setDestination] = useState('');
    const [isRouting, setIsRouting] = useState(false);
    const gpxUploaderRef = useRef();
    const routingControlRef = useRef();
    const destinationMarkerRef = useRef();
    const userMarkerRef = useRef();
    const watchIdRef = useRef(null);

    const [showGeoLocationPopup, setShowGeoLocationPopup] = useState(false); // Afficher la popup de géolocalisation

    // Créer les icônes personnalisées
    const startIcon = new L.Icon({
        iconUrl: startIconImage,
        iconSize: [32, 32], // Taille de l'icône
        iconAnchor: [16, 32] // Point d'ancrage de l'icône (centre en bas)
    });

    const endIcon = new L.Icon({
        iconUrl: endIconImage,
        iconSize: [32, 32], // Taille de l'icône
        iconAnchor: [16, 32] // Point d'ancrage de l'icône (centre en bas)
    });

    const userIcon = new L.Icon({
        iconUrl: userIconImage,
        iconSize: [32, 32], // Taille de l'icône
        iconAnchor: [16, 32] // Point d'ancrage de l'icône (centre en bas)
    });

    // useEffect(() => {
    //     // console.log('Map component mounted');
    //     // Afficher la popup de géolocalisation si l'utilisateur n'a pas encore vu la popup
    //     const hasSeenGeoLocationPopup = localStorage.getItem('hasSeenGeoLocationPopup');
    //     if (!hasSeenGeoLocationPopup && 'geolocation' in navigator) {
    //         setShowGeoLocationPopup(true);
    //     }
    // }, []);

    const handleCloseGeoLocationPopup = () => {
        setShowGeoLocationPopup(false);
        localStorage.setItem('hasSeenGeoLocationPopup', 'true'); // Enregistrer dans le stockage local que l'utilisateur a vu la popup
    };


    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                setUserLocation({ latitude, longitude });
            }, (error) => {
                console.error('Error getting user location:', error);
            });
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    useEffect(() => {
        getUserLocation();
    }, []);

    const watchUserLocation = () => {
        if (navigator.geolocation) {
            watchIdRef.current = navigator.geolocation.watchPosition((position) => {
                
                const { latitude, longitude } = position.coords;
                // Vérifier les données de localisation de l'utilisateur avant de les utiliser pour éviter les erreurs latitude et longitude
                // if (userLocation) {
                    setUserLocation({ latitude, longitude });
                // }
                
                // Vérifiez que le marqueur et la carte existent avant de les utiliser
                if (userMarkerRef.current && map) {
                    userMarkerRef.current.setLatLng([latitude, longitude]);
                }
            }, (error) => {
                console.error('Error watching user location:', error);
            });
        }
    };

    useEffect(() => {
        return () => {
            if (watchIdRef.current) {
                navigator.geolocation.clearWatch(watchIdRef.current);
            }
            if (map) {
                map.remove();
            }
        };
    }, []);

    useEffect(() => {
        // Initialiser la carte une fois que les données de localisation sont disponibles
        if (userLocation) {
            console.log('User location:', userLocation);
            const map = L.map('map').setView([userLocation.latitude, userLocation.longitude], 13);
            setMap(map);
            
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; OpenStreetMap contributors'
            }).addTo(map);

            // Ajouter un marqueur pour la position de l'utilisateur
            userMarkerRef.current = L.marker([userLocation.latitude, userLocation.longitude], { icon: userIcon })
                .bindPopup('Votre position actuelle : ' + userLocation.latitude.toFixed(4) + ', ' + userLocation.longitude.toFixed(4))
                .addTo(map);

            map.on('click', (e) => {
                setDestination(e.latlng);
                if (destinationMarkerRef.current) {
                    destinationMarkerRef.current.setLatLng(e.latlng).bindPopup('Destination').openPopup();
                } else {
                    destinationMarkerRef.current = L.marker(e.latlng, { icon: endIcon })
                        // .bindPopup('Destination')
                        .addTo(map);
                }
            });

            // Démarrer la surveillance de la position de l'utilisateur
            // watchUserLocation();

            // Nettoyer la carte lorsque le composant est démonté
            return () => {
                map.remove();
                if (watchIdRef.current) {
                    navigator.geolocation.clearWatch(watchIdRef.current);
                }
            };
        }
    }, [userLocation]);


    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    const handleUpdateLegend = (gpxLayer) => {
        setLegendItems([]);
        const name = gpxLayer._info ? gpxLayer._info.name : 'Nom inconnu';
        const newLegendItem = {
            name: name,
            color: gpxLayer.options.polyline_options.color
        };
        setLegendItems((prevLegendItems) => [...prevLegendItems, newLegendItem]);
    };

    const loadGpxFileToMap = (file) => {
        if (map) {
            let gpxFile = null;
            switch (file) {
                case '29km Marche':
                    gpxFile = gpxFile29km;
                    break;
                case '34km Ronde Des Douaniers':
                    gpxFile = gpxFile34km;
                    break;
                case '56km Raid Du Golfe':
                    gpxFile = gpxFile56km;
                    break;
                case '70km Raid Du Golfe':
                    gpxFile = gpxFile70km;
                    break;
                case '100km Raid Du Golfe':
                    gpxFile = gpxFile100km;
                    break;
                case '175km Grand Raid Du Golfe':
                    gpxFile = gpxFile175km;
                    break;
                default:
                    console.error('Invalid GPX file:', file);
                    return;
            }

            const gpxLayer = new L.GPX(gpxFile, {
                async: true,
                polyline_options: {
                    color: 'blue'
                },
                marker_options: {
                    startIconUrl: startIconImage,
                    endIconUrl: endIconImage,
                    shadowUrl: null,
                    wptIconUrls: {
                        '': ravitoIcon
                    },
                    iconSize: [32, 32],
                    iconAnchor: [16, 32]
                }
            });

            // Clear the map before adding a new layer
            map.eachLayer((layer) => {
                if (layer instanceof L.GPX) {
                    map.removeLayer(layer);
                }
            });
            gpxLayer.addTo(map);

            gpxLayer.on('loaded', () => {
                handleUpdateLegend(gpxLayer);

                const bounds = gpxLayer.getBounds(); // Récupérer les limites du GPX
                if (bounds.isValid()) {
                    map.fitBounds(bounds); // Ajuster la carte pour encadrer les limites du GPX
                }
            });

        }
    };


    const handleDestinationChange = (e) => {
        const [lat, lng] = e.target.value.split(',');
        setDestination(L.latLng(lat, lng));
    };

    const handlePlanRoute = () => {
        if (map && userLocation && destination) {
            // Supprimer l'ancien itinéraire si existe
            if (routingControlRef.current) {
                map.removeControl(routingControlRef.current);
            }

            // Ajouter le nouveau contrôle d'itinéraire
            routingControlRef.current = L.Routing.control({
                waypoints: [
                    L.latLng(userLocation.latitude, userLocation.longitude),
                    destination
                ],
                routeWhileDragging: true,
                createMarker: function (i, waypoint, n) {
                    // Utiliser différentes icônes pour le départ et l'arrivée
                    if (i === 0) {
                        return L.marker(waypoint.latLng, { icon: startIcon }).bindPopup('Départ : ' + userLocation.latitude.toFixed(2) + ', ' + userLocation.longitude.toFixed(2));
                    } else if (i === n - 1) {
                        // Raccourir les coordonnées à 2 chiffres après la virgule
                        return L.marker(waypoint.latLng, { icon: endIcon }).bindPopup('Destination : ' + destination.lat.toFixed(2) + ', ' + destination.lng.toFixed(2));
                    }
                }
            }).addTo(map);

            setIsRouting(true);
        }
    };

    const handleClearRoute = () => {
        if (map && routingControlRef.current) {
            map.removeControl(routingControlRef.current);
            routingControlRef.current = null;
            setIsRouting(false);
            if (destinationMarkerRef.current) {
                map.removeLayer(destinationMarkerRef.current);
                destinationMarkerRef.current = null;
            }
            setDestination('');
        }
    };

    // Fonction pour générer un lien vers Google Maps
    // Fonction pour générer un lien vers Google Maps
    const getGoogleMapsLink = () => {
        if (userLocation && destination) {
            return `https://www.google.com/maps/dir/?api=1&origin=${userLocation.latitude},${userLocation.longitude}&destination=${destination.lat},${destination.lng}`;
        }
        return '#';
    };

    // Fonction pour générer un lien vers Waze
    const getWazeLink = () => {
        if (userLocation && destination) {
            return `https://www.waze.com/ul?ll=${destination.lat},${destination.lng}&navigate=yes&zoom=17`;
        }
        return '#';
    };


    return (
        <div className="map-container">
            <div id="map" className="map"></div>
            <div className="overlay">
                <div className="elements">
                    {map && selectedOption === 'import' && <GPXUploader map={map} ref={gpxUploaderRef} />}
                    <GPSOptions onOptionChange={handleOptionChange} onLoadGpxFile={loadGpxFileToMap} />
                    <div className="route-planner">
                        <input
                            className="route-planner-input"
                            type="text"
                            value={destination.toString()}
                            onChange={handleDestinationChange}
                            placeholder="Cliquer sur la carte pour placer un point"
                            disabled
                        />
                        <button className="route-planner-button" onClick={handlePlanRoute} disabled={!destination}>
                            Planifier l'itinéraire
                        </button>
                        <button className="route-planner-button" onClick={handleClearRoute} disabled={!isRouting}>
                            Effacer l'itinéraire
                        </button>
                        <button className="route-planner-button" onClick={getUserLocation}>
                            Relocaliser
                        </button>
                        {destination && (
                            <>
                                <a
                                    className="route-planner-button"
                                    href={getGoogleMapsLink()}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Google Maps
                                </a>
                                <a
                                    className="route-planner-button"
                                    href={getWazeLink()}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Waze
                                </a>
                            </>
                        )}
                    </div>

                </div>
            </div>

            {/* Inclure le composant GeoLocationPopup */}
            {showGeoLocationPopup && <GeoLocationPopup onClose={handleCloseGeoLocationPopup} />}

            <footer>
                <p><a href="https://www.ultra-marin.fr/ultra-marin">Site officiel de l'Ultre Marin</a> | </p>
                <p> <a href="https://live.breizhchrono.com/external/live5/dashboard.jsp?reference=1605741692033-4">Suivi des coureur en live.</a> | </p>
                <p>Application créee par <a href='https://www.linkedin.com/in/pierre-jean-le-roy/'>Pierre-Jean LE ROY</a></p>
            </footer>
        </div>
    );

}

export default Map;
