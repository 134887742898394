import React, { useState, useEffect } from 'react';

function GeoLocationPopup() {
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        const isGeoLocationEnabled = 'geolocation' in navigator;
        const hasSeenPopup = localStorage.getItem('hasSeenGeoLocationPopup');

        if (isGeoLocationEnabled && !hasSeenPopup) {
            setShowPopup(true);
        }
    }, []);

    const handleClosePopup = () => {
        setShowPopup(false);
        localStorage.setItem('hasSeenGeoLocationPopup', 'true');
    };

    return (
        <div style={{ display: showPopup ? 'block' : 'none' }}>
            <div className="popup-container">
                <h2>Activer la géolocalisation</h2>
                <p>Pour utiliser pleinement les fonctionnalités de l'application, veuillez activer la géolocalisation sur votre appareil.</p>
                <button onClick={handleClosePopup}>Fermer</button>
            </div>
        </div>
    );
}

export default GeoLocationPopup;
